import Logo from '../logo/Logo';
import './Footer.scss';

function Footer() {
  return (
    <section className="footer">
      <div className="logo-contact">
        <Logo />
      </div>
    </section>
  )
}

export default Footer;
