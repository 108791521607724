import './Talks.scss';

function Projects() {
  return (
    <section className="projects" id="projects">
      <h1 className="page-title">Talks</h1>
      <div className="project-list">
        <div className="project">
          <h1 className="medium-font">HacktoberFest na Globo: Como funciona?</h1>
<iframe width="640" height="360" src="https://www.youtube.com/embed/akUO6jP-HJ8" title="GlobotechCast | Episódio 54 – HacktoberFest na Globo: Como funciona?" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
          <p className="small-font">
            #openSource #globo #hacktoberFest
          </p>
        </div> 
        <div className="project">
          <h1 className="medium-font">Gitlab-CI: O que é e por que utilizar?</h1>
<iframe width="640" height="360" src="https://www.youtube.com/embed/DcjGNgso314" title="Engcast #07 - Gitlab-CI: O que é e por que utilizar?" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
          <p className="small-font">
            #gitlab-ci #engdb #devops
          </p>
        </div> 
        <div className="project">
          <h1 className="medium-font">Microsserviços modulares em produção</h1>
          <iframe width="640" height="360" src="https://www.youtube.com/embed/nItgmEkZqEY" title="Engcast #02 - Microsserviços modulares em produção" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
          <p className="small-font">
            #microservicos #engdb #develop
          </p>
        </div> 
        <div className="project">
          <h1 className="medium-font">Engeek Tech Beer - Episodio 02</h1>
          <iframe width="640" height="360" src="https://www.youtube.com/embed/SNYWsZDTYs0" title="Engeek Tech Beer - Episodio 02" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
          <p className="small-font">
            #engdb
          </p>
        </div> 
        <div className="project">
          <h1 className="medium-font">Engeek Tech Beer -  Episodio 01</h1>
          <iframe width="640" height="360" src="https://www.youtube.com/embed/WTUIqA5Jrbg" title="Engeek Tech Beer -  Episodio 01" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
          <p className="small-font">
            #engdb
          </p>
        </div> 
      </div>
    </section>
  )
}

export default Projects;
