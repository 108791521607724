import React from 'react';
import {ReactComponent as LogoSvg} from '../../assets/images/raphael-rossi.svg';
import './Logo.scss'

function Logo() {
  return (
    <LogoSvg className="logo"/> 
  ) 
}

export default Logo;
